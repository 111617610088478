<template>
  <v-main class="grey lighten-5">
    <div class="py-16 text-center" v-if="isLoading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-container class="pt-8 pb-16" v-if="!isLoading">
      <v-row class="mb-8">
        <v-col cols="12" class="pa-5 d-flex flex-column">
          <h3 class="text-h4 black--text d-inline-block mb-8">
            {{ $t("articles") }}
          </h3>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="4"
              v-for="item in articlesOrdered"
              :key="item.uid"
            >
              <ArticleItem :item="item" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-pagination
        v-if="totalPages > 1"
        v-model="page"
        :length="totalPages"
        :total-visible="7"
      ></v-pagination>
    </v-container>
  </v-main>
</template>
<script>
import ArticleItem from "../components/ArticleItem";
import { getTopicsPath, getArticlesPath } from "../router/index";

export default {
  props: {
    topics: {
      type: Array,
    },
  },
  data: () => ({
    isLoading: true,
    page: 1,
    pageSize: 9,
    articles: {},
    tags: null,
  }),
  components: { ArticleItem },
  watch: {
    "$i18n.locale"() {
      this.loadContent();
    },
    page() {
      this.loadContent();
    },
    "$route.query.tags"() {
      this.loadContent();
    },
  },
  computed: {
    totalPages() {
      return this.articles.total_pages;
    },
    locale() {
      return "es-es"; //this.$i18n.locale === "es" ? "es-es" : "en-us";
    },
    topicsPath() {
      return getTopicsPath(this.$i18n.locale);
    },
    articlesPath() {
      return getArticlesPath(this.$i18n.locale);
    },
    articlesOrdered() {
      return this.articles.results.sortBy("position");
    },
  },
  methods: {
    setTitle() {
      this.$title = this.$t("articles");
    },
    async loadContent() {
      this.isLoading = true;
      await this.getArticles();
      this.isLoading = false;
      this.setTitle();
    },
    async getArticles() {
      const query = [this.$prismic.Predicates.at("document.type", "articles")];
      if (this.tags) {
        query.push(
          this.$prismic.Predicates.any("document.tags", this.tags.split(","))
        );
      }

      const document = await this.$prismic.client.query(query, {
        orderings: "[document.first_publication_date desc]",
        lang: this.locale,
        page: this.page,
        pageSize: this.pageSize,
      });
      this.articles = document;
    },
  },
  created() {
    this.tags = this.$route.query.tags;
    this.loadContent();
  },
  beforeRouteUpdate(to, from, next) {
    this.loadContent();
    next();
  },
};
</script>